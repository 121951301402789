














































import { Component, Prop, PropSync } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";
import { get, orderBy } from "lodash";
import TranslatableComponent from "@/lib/mixins/translateable";
import { NestedKeyOf } from "@/components/tables/types";
import ScrollIndicator from "@/components/tables/ScrollIndicator.vue";

export type SortDirection = "asc" | "desc";

export interface TableHeaders<T extends object> {
  property: NestedKeyOf<T>;
  name: string | TranslateResult;
}

@Component({
  components: { ScrollIndicator },
})
export default class SortableTable<
  T extends object
> extends TranslatableComponent {
  @Prop()
  readonly data!: T[];

  @Prop()
  private headers!: TableHeaders<T>[];

  @Prop()
  private index!: keyof T;

  @PropSync("sortColumn")
  private sortBy!: string;

  @PropSync("sortDirection")
  private sortDir!: SortDirection;

  get processedData(): T[] {
    if (!this.sortBy || !this.sortDir) {
      return this.data;
    }
    return orderBy(
      this.data,
      (entry) => {
        const value = get(entry, this.sortBy);

        if (!value) return "";

        return typeof value === "string"
          ? value.toLowerCase()
          : value.toString().toLowerCase();
      },
      this.sortDir
    );
  }

  changeSorting(column: TableHeaders<T>) {
    if (this.sortBy !== column.property) {
      this.sortBy = column.property;
      this.sortDir = "asc";
    } else {
      this.sortDir = this.sortDir === "asc" ? "desc" : "asc";
    }
  }
}
