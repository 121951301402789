











































import DocumentsOverview from "@/components/documents/DocumentsOverview.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import { Document } from "@/models/document";
import { tasksApi, DocumentParameters } from "@/api/portal/tasks";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import { TaskCategory, taskFilter } from "@/models/task";
import { Role } from "@/models/role";
import { namespace } from "vuex-class";
import VueI18n from "vue-i18n";
import { participantTypeFromRole } from "@/models/participant";
import { employeeMyTaskFilter } from "@/utils/tasks";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";
import { taskReadApi } from "@/api/portal/task-read-api";

const user = namespace("user");
@Component({
  components: { TheHeader, DocumentsOverview, Page, Loader },
})
@Translatable("documents")
export default class Documents extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop()
  private category!: TaskCategory;

  @Prop({ required: false, default: undefined })
  private selectedAccountId: string | undefined;

  @user.State("role")
  private role!: Role;

  @user.State("userId")
  private userId!: string;

  private loading: boolean = false;

  private documents: Document[] = [];

  get hasDocuments(): boolean {
    return this.tasks.length > 0;
  }

  get pageSettings(): {
    header: {
      headerImage: string;
      title: VueI18n.TranslateResult;
      subtitle: VueI18n.TranslateResult;
    };
  } {
    if (this.role === "customer") {
      return {
        header: {
          headerImage: "merkbeeld2.jpg",
          title: this.translated_view_value("my_tasks.header.title"),
          subtitle: this.translated_view_value("my_tasks.header.subtitle"),
        },
      };
    }
    const translations: Record<
      TaskCategory,
      {
        header: {
          headerImage: string;
          title: VueI18n.TranslateResult;
          subtitle: VueI18n.TranslateResult;
        };
      }
    > = {
      my: {
        header: {
          headerImage: "merkbeeld2.jpg",
          title: this.translated_view_value("my_tasks.header.title"),
          subtitle: this.translated_view_value("my_tasks.header.subtitle"),
        },
      },
      team: {
        header: {
          headerImage: "teamtasks.jpg",
          title: this.translated_view_value("my_team_tasks.header.title"),
          subtitle: this.translated_view_value("my_team_tasks.header.subtitle"),
        },
      },
      active: {
        header: {
          headerImage: "activetasks.jpg",
          title: this.translated_view_value("my_active_tasks.header.title"),
          subtitle: this.translated_view_value(
            "my_active_tasks.header.subtitle"
          ),
        },
      },
      customer: {
        header: {
          headerImage: "customertasks.jpg",
          title: this.translated_view_value("my_customer_tasks.header.title"),
          subtitle: this.translated_view_value(
            "my_customer_tasks.header.subtitle"
          ),
        },
      },
    };
    return translations[this.category];
  }

  get taskCategory(): TaskCategory {
    if (this.role === "customer") {
      return "my";
    }
    return this.category;
  }

  get documentsParams(): DocumentParameters {
    const { scope, documentStatus } = taskFilter(this.category, this.role);
    return { scope, status: documentStatus };
  }

  mounted() {
    this.getDocuments(this.documentsParams);
  }

  get tasks(): Document[] {
    if (this.taskCategory === "my" && this.role === "employee")
      return employeeMyTaskFilter(
        this.documents,
        this.userId,
        participantTypeFromRole(this.role)
      );
    return this.documents;
  }

  @Watch("documentsParams")
  private getDocuments(params?: DocumentParameters) {
    this.loading = true;
    taskReadApi
      .tasks(params)
      .then((response) => {
        this.documents = response.data;
      })
      .catch(() => {
        this.documents = [];
        this.$snotify.error(this.translated_view_value("loading.error"));
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
