

















































































import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Document, DocumentScope } from "@/models/document";
import { Account } from "@/interfaces/Account";
import djlMultiSelect from "@/components/form-components/djlMultiselect.vue";
import { Breakpoints } from "@/store/modules/layout";
import { formatIsoDate } from "@/filters";
import { get, uniqBy } from "lodash";

import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import SortableTable, {
  SortDirection,
  TableHeaders,
} from "@/components/tables/SortableTable.vue";
import AccountFilter from "@/components/form-components/search/AccountFilter.vue";
import { TaskCategory } from "@/models/task";
import { filteredData } from "@/components/tables/filter";
import { Role } from "@/models/role";
import { accountLabel } from "@/utils/accountDisplayFormatter";

const layout = namespace("layout");
const user = namespace("user");

class SortableTask extends Document {
  employeeSignerName: string;

  constructor(document?: Partial<Document>) {
    super();
    Object.assign(this, document);
    this.employeeSignerName = document?.employeeSigner
      ? `${document?.employeeSigner?.firstName} ${document?.employeeSigner?.lastName}`
      : "";
  }

  static fromDocument(document: Document): Document {
    return new SortableTask(document);
  }
}

interface OrderedTableHeaders extends TableHeaders<SortableTask> {
  order: number;
  class?: string | null;
}

@Component({
  components: { AccountFilter, SortableTable, djlMultiSelect },
  methods: { formatIsoDate },
})
@Translatable("documents")
export default class DocumentsOverview extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Ref() readonly accountMultiselect!: djlMultiSelect;

  Document = Document;

  @Prop()
  private documents!: Document[];

  @Prop()
  private taskCategory!: TaskCategory;

  @Prop({ required: false, default: undefined })
  private selectedAccountId: string | undefined;

  @layout.Getter
  public responsiveAtLeast!: (breakpoint: Breakpoints) => boolean;

  @layout.Getter
  public responsiveUpTo!: (breakpoint: Breakpoints) => boolean;

  @user.State("role")
  private role!: Role;

  @Prop()
  private scope?: DocumentScope;
  private filter: Account | null = null;

  private sortBy: string = "endDate";
  private sortDirection: SortDirection = "asc";

  @Watch("filter")
  private filters(): ((o: Document) => boolean)[] {
    return [
      (o: Document) => {
        return this.filter ? get(o, "account.name") === this.filter.name : true;
      },
    ];
  }

  get sortableDocuments() {
    return filteredData(
      this.documents.map((e) => SortableTask.fromDocument(e)),
      this.filters()
    );
  }

  get customLabel() {
    return this.role === "employee" ? accountLabel : undefined;
  }

  private getTableHeaders() {
    const headers: OrderedTableHeaders[] = [
      {
        property: "documentType",
        name: this.$t("models.document.documentType"),
        order: 1,
      },
      {
        property: "period.value",
        name: this.$t("models.document.period"),
        order: 2,
        class: "hidden-tablet-down",
      },
      {
        property: "account.name",
        name: this.$t("models.document.account"),
        order: 3,
      },
      {
        property: "organization.name",
        name: this.$t("models.document.organization"),
        order: 4,
        class: "hidden-tablet-down",
      },
      {
        property: "endDate",
        name: this.$t("models.document.endDate"),
        order: 5,
      },
      {
        property: "state",
        name: this.$t("models.document.state"),
        order: 6,
      },
    ];

    if (this.scope === "ALL") {
      headers.push({
        property: "employeeSignerName",
        name: this.$t("models.document.signer_employee"),
        order: 8,
      });
    }

    return headers.sort((a, b) => a.order - b.order);
  }

  get accounts(): Account[] {
    return uniqBy(
      this.documents.map((document) => document.account),
      "id"
    );
  }

  private onDocumentClicked(document: Document) {
    this.$router.push({
      name: "documents-show",
      params: { documentId: document.id },
      query: {
        category: this.taskCategory,
        selectedAccountId: this.filter?.id ?? undefined,
      },
    });
  }

  private onAccountChanged(account: Account | null) {
    const currentQuery = { ...this.$route?.query };
    if (!account) {
      delete currentQuery.selectedAccountId;
    } else {
      currentQuery.selectedAccountId = account.id;
    }
    this.$router.replace({ query: currentQuery });
    this.filter = account;
  }

  private accountNameFromDocument(document: Document) {
    if (!this.documentHasEntity(document)) {
      return "Geen entiteit gekoppeld";
    }
    return document.account.name;
  }

  private documentHasEntity(document: Document) {
    return !!document.account;
  }
}
